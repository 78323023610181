<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row justify-content-start align-items-center">
          <div class="col-auto">表示期間</div>
          <div class="col-auto">
            <FormRow>
              <template v-slot:content>
                <div class="form-content-row">
                  <div class="form-group">
                    <div class="form-group-item">
                      <div class="form-icon form-date">
                        <Flatpickr
                          class="form-control form-icon-input form-date-input"
                          name="startDate"
                          v-model="startDate"
                          :config="config.noLimit"
                        />
                      </div>
                    </div>
                    <div class="form-group-item">
                      〜
                    </div>
                    <div class="form-group-item">
                      <div class="form-icon form-date">
                        <Flatpickr
                          class="form-control form-icon-input form-date-input"
                          name="endDate"
                          v-model="endDate"
                          :config="config.noLimit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </FormRow>
          </div>
          <div class="col-auto">
            <ActionButton
              class="btn btn-main"
              :handle-submit="handleSearchPoint"
              button-text="表示"
            />
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <table class="dataTable table-summary-point">
                    <tbody>
                      <tr class="listGroup-point"></tr>
                      <tr>
                        <th class="title-table">発行済みポイント</th>
                        <td class="point-main">{{ form.totalAddPoint | comma }}P</td>
                      </tr>
                      <tr v-if="paymentPointDetail">
                        <th class="sub-title-table">支払付与</th>
                        <td>{{ form.byAddPayment | comma }}P</td>
                      </tr>
                      <tr v-if="chargePointDetail">
                        <th class="sub-title-table">購入付与</th>
                        <td>{{ form.byCharge | comma }}P</td>
                      </tr>
                      <template v-if="form.byAddCategory">
                        <tr v-for="category in form.byAddCategory" :key="category.index">
                          <th class="sub-title-table">{{ category.name }}</th>
                          <td>{{ category.value | comma }}P</td>
                        </tr>
                      </template>
                      <tr>
                        <th class="sub-title-table">手動付与</th>
                        <td>{{ form.byAddManual | comma }}P</td>
                      </tr>
                      <tr v-if="registerCustomerPointDetail">
                        <th class="sub-title-table">会員登録付与</th>
                        <td>{{ form.byRegister | comma }}P</td>
                      </tr>
                      <tr v-if="byStampPointDetail">
                        <th class="sub-title-table">スタンプラリー付与</th>
                        <td>{{ form.byStamp | comma }}P</td>
                      </tr>
                      <tr>
                        <th class="title-table">使用済みポイント</th>
                        <td class="point-danger">{{ form.totalUsePoint | comma }}P</td>
                      </tr>
                      <tr>
                        <th class="sub-title-table">支払消費</th>
                        <td>{{ form.byUsePayment | comma }}P</td>
                      </tr>
                      <template v-if="form.byUseCategory">
                        <tr v-for="category in form.byUseCategory" :key="category.index">
                          <th class="sub-title-table">{{ category.name }}</th>
                          <td>{{ category.value | comma }}P</td>
                        </tr>
                      </template>
                      <tr>
                        <th class="sub-title-table">手動消費</th>
                        <td>{{ form.byUseManual | comma }}P</td>
                      </tr>
                      <tr>
                        <th class="title-table">失効ポイント</th>
                        <td class="point-danger">{{ form.expired | comma }}P</td>
                      </tr>
                      <tr>
                        <th class="title-table">ポイント差額</th>
                        <td class="point-main">{{ form.active | comma }}P</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//mixins
import flatpickr from '@/mixins/plugin/flatpickr';
import nav from '@/mixins/nav/point';
import error from '@/mixins/plugin/error';
import modal from '@/mixins/plugin/modal';
//components
import Flatpickr from 'vue-flatpickr-component';
import FormRow from '@/components/FormRow.vue';

export default {
  data: function() {
    return {
      pageName: 'ポイント',
      startDate: null,
      endDate: null,
      form: {
        totalAddPoint: 0,
        byCharge: 0,
        byAddPayment: 0,
        byAddCategory: null,
        byAddManual: 0,
        byRegister: 0,
        byStamp: 0,
        totalUsePoint: 0,
        byUsePayment: 0,
        byUseCategory: null,
        byUseManual: 0,
        expired: 0,
        active: 0
      }
    };
  },
  components: {
    FormRow,
    Flatpickr
  },
  mixins: [nav, error, modal, flatpickr],
  validations: {
    categoryName: { required, maxLength: maxLength(10) },
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      parentAggregratePointDetail: 'parentPoint/aggregratePointDetail',
      childAggregratePointDetail: 'childPoint/aggregratePointDetail',
    }),
    aggregratePointDetail() {
      return this.isPointCommon ? this.parentAggregratePointDetail : this.childAggregratePointDetail;
    },
    paymentPointDetail() {
      return this.aggregratePointDetail?.add?.byPayment !== null;
    },
    chargePointDetail() {
      return this.aggregratePointDetail?.add?.byCharge !== null;
    },
    registerCustomerPointDetail() {
      return this.aggregratePointDetail?.add?.byRegister !== null;
    },
    byStampPointDetail() {
      return this.aggregratePointDetail?.add?.byStamp !== null;
    },
  },
  methods: {
    async handleSearchPoint() {
      const loading = this.$loading.show();
      await this.getAggregatePoint();
      this.convertForm();
      this.$loading.clear(loading);
    },
    getAggregatePoint() {
      const point = this.isPointCommon ? 'parentPoint' : 'childPoint';
      const data = {
        startDate: this.startDate,
        endDate: this.endDate
      };
      return this.$store.dispatch(`${point}/getAggregatePoint`, data);
    },
    convertForm() {
      this.startDate = this.aggregratePointDetail?.startDate;
      this.endDate = this.aggregratePointDetail?.endDate;
      this.form = {
        ...this.aggregratePointDetail,
        totalAddPoint: this.aggregratePointDetail?.add?.totalPoint || 0,
        byCharge: this.aggregratePointDetail?.add?.byCharge || 0,
        byAddPayment: this.aggregratePointDetail?.add?.byPayment || 0,
        byAddCategory: this.aggregratePointDetail?.add?.byCategory || [],
        byAddManual: this.aggregratePointDetail?.add?.byManual || 0,
        byRegister: this.aggregratePointDetail?.add?.byRegister || 0,
        byStamp: this.aggregratePointDetail?.add?.byStamp || 0,
        totalUsePoint: this.aggregratePointDetail?.use?.totalPoint || 0,
        byUsePayment: this.aggregratePointDetail?.use?.byPayment || 0,
        byUseCategory: this.aggregratePointDetail?.use?.byCategory || [],
        byUseManual: this.aggregratePointDetail?.use?.byManual || 0,
        expired: this.aggregratePointDetail?.expired || 0,
        active: this.aggregratePointDetail?.active || 0,
      }
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
    this.getAggregatePoint()
    ]).finally(() => {
      this.convertForm();
      this.$loading.clear(loading);
    })
  },
};
</script>

<style scoped>
.table-summary-point tbody tr td {
  text-align: right;
  font-weight: normal;
  color: #000;
}

.title-table {
  color: #000;
  font-size: 16px;
  text-align: left !important;
}

.sub-title-table {
  text-align: left;
  padding-left: 50px !important;
  color: #000;
  font-weight: normal;
}

.point-main {
  color: #2e5cf1 !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.point-danger {
  color: red !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
</style>
